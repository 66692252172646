// 长地址格式化-钱包地址格式化
const addressFormat=function(address) {
    if(address){
        if(address.length>30){
            var left=address.slice(0,15)
            var right=address.slice(-15)
            return left+'...'+right
        }else{
            return address
        }
    }else{
        return ''
    }
}
export default addressFormat